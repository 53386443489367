<template lang="pug">

v-row
  inch-converter
  price-calculator
  // conversionTracker

</template>

<script>
import inchConverter from '@/components/toolbox/inch-converter'
import priceCalculator from '@/components/toolbox/price-calculator.vue'
import conversionTracker from '@/components/toolbox/conversion-tracker.vue'

export default {
  components: {
    inchConverter,
    priceCalculator,
    conversionTracker
  }
}
</script>

<style lang="stylus"></style>
