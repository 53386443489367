<template lang="pug">

v-card(outlined)
  v-col
    v-list-item(three-line)
      v-list-item-content
        v-list-item-title.headline.mb-1 Inches to Feet Converter
        v-list-item-subtitle Easily convert an inch value to foot+inches value.
  v-divider
  v-card-text
    v-radio-group(v-model="mode" row)
      v-radio(label="Inches to Feet" value="inches-to-feet")
      v-radio(label="Feet to Inches" value="feet-to-inches")
    v-row(v-if="mode === 'inches-to-feet'")
      v-col
        v-text-field(
          outlined
          clearable
          v-model="inches"
          type="number"
          label="Inches"
          prepend-inner-icon="square_foot"
          hint="Enter total in inches."
        )
      v-col
        p.text-h3(v-if="inches")  = {{ feetPlusInches }}
    v-row(v-if="mode === 'feet-to-inches'")
      v-col(cols="6" md="4")
        v-text-field(
          outlined
          clearable
          v-model="feet"
          type="number"
          label="Feet"
          prepend-inner-icon="square_foot"
          hint="Enter total in feet"
        )
      v-col(cols="6" md="4")
        v-text-field(
          outlined
          clearable
          v-model="additionalInches"
          type="number"
          label="Inches"
          prepend-inner-icon="square_foot"
          hint="Enter additional inches"
        )
      v-col.offset-6.offset-md-0(cols="6" md="4")
        p.text-h3(v-if="inches")  = {{ inches }}"

</template>

<script>
export default {
  data () {
    return {
      inchesInput: '',
      inches: '',
      mode: 'inches-to-feet'
    }
  },
  methods: {},
  computed: {
    feet: {
      get: function () {
        if (this.inches) {
          return Math.floor(this.inches / 12)
        } else {
          return ''
        }
      },
      set: function (val) {
        this.inches = (val * 12) + this.additionalInches
      }
    },
    feetPlusInches () {
      if (this.inches) {
        const totalInches = this.inches
        const feet = Math.floor(totalInches / 12)
        const inches = totalInches % 12
        if (inches === 0) {
          return feet + "'"
        } else if (feet === 0) {
          return inches + '"'
        } else {
          return feet + "'" + inches + '"'
        }
      } else {
        return ''
      }
    },
    additionalInches: {
      get: function () {
        if (this.inches) {
          return this.inches % 12
        } else {
          return ''
        }
      },
      set: function (val) {
        this.inches = (this.feet * 12) + +val
      }
    }
  }
}
</script>
