<template lang="pug">

v-card(outlined)
  v-col
    v-list-item(three-line)
      v-list-item-content
        v-list-item-title.headline.mb-1 Conversion Tracker
        v-list-item-subtitle Tracks how many sales came from each platform.
  v-divider
  v-list
    v-list-item-group
      v-list-item
        v-list-item-icon
          v-icon web
        v-list-item-content
          v-list-item-title Facebook
        v-list-item-action
          v-icon(v-if="edit" @click="facebookCounter++") add_circle
          span {{facebookCounter}}
          v-icon(v-if="edit" @click="facebookCounter--") remove_circle
      v-list-item
        v-list-item-icon
          v-icon web
        v-list-item-content
          v-list-item-title Instagram
        v-list-item-action
          v-icon(v-if="edit" @click="instagramCounter++") add_circle
          span {{instagramCounter}}
          v-icon(v-if="edit" @click="instagramCounter--") remove_circle
      v-list-item
        v-list-item-icon
          v-icon web
        v-list-item-content
          v-list-item-title KSL
        v-list-item-action
          v-icon(v-if="edit" @click="kslCounter++") add_circle
          span {{kslCounter}}
          v-icon(v-if="edit" @click="kslCounter--") remove_circle
      v-list-item
        v-list-item-icon
          v-icon language
        v-list-item-content
          v-list-item-title wasatchtrailer.com
        v-list-item-action
          v-icon(v-if="edit" @click="websiteCounter++") add_circle
          span {{websiteCounter}}
          v-icon(v-if="edit" @click="websiteCounter--") remove_circle
      v-list-item
        v-list-item-icon
          v-icon repeat
        v-list-item-content
          v-list-item-title Repeat
        v-list-item-action
          v-icon(v-if="edit" @click="repeatCounter++") add_circle
          span {{repeatCounter}}
          v-icon(v-if="edit" @click="repeatCounter--") remove_circle
      v-list-item
        v-list-item-icon
          v-icon question_answer
        v-list-item-content
          v-list-item-title Referral
        v-list-item-action
          v-icon(v-if="edit" @click="referralCounter++") add_circle
          span {{referralCounter}}
          v-icon(v-if="edit" @click="referralCounter--") remove_circle
      v-list-item
        v-list-item-icon
          v-icon location_on
        v-list-item-content
          v-list-item-title Location
        v-list-item-action
          v-icon(v-if="edit" @click="locationCounter++") add_circle
          span {{locationCounter}}
          v-icon(v-if="edit" @click="locationCounter--") remove_circle
  v-divider
  v-card-actions
    v-btn(v-if="!edit" @click="edit = !edit" color="primary" text)
      v-icon(left) lock
      | Unlock
    v-btn(v-if="edit" @click="edit = !edit" color="primary")
      v-icon(left) lock
      | Lock
</template>

<script>
export default {
  data () {
    return {
      edit: false
    }
  },
  mounted () {
    this.$store.dispatch('loadedConversions')
  },
  computed: {
    kslCounter: {
      get () {
        const test = this.$store.getters.getKsl
        return test.total
      },
      set (value) {
        this.$store.dispatch('updateConversion', { name: 'ksl', total: value })
      }
    },
    websiteCounter: {
      get () {
        const test = this.$store.getters.getWebsite
        return test.total
      },
      set (value) {
        this.$store.dispatch('updateConversion', { name: 'website', total: value })
      }
    },
    instagramCounter: {
      get () {
        const test = this.$store.getters.getInstagram
        return test.total
      },
      set (value) {
        this.$store.dispatch('updateConversion', { name: 'instagram', total: value })
      }
    },
    facebookCounter: {
      get () {
        const test = this.$store.getters.getFacebook
        return test.total
      },
      set (value) {
        this.$store.dispatch('updateConversion', { name: 'facebook', total: value })
      }
    },
    repeatCounter: {
      get () {
        const test = this.$store.getters.getRepeat
        return test.total
      },
      set (value) {
        this.$store.dispatch('updateConversion', { name: 'repeat', total: value })
      }
    },
    referralCounter: {
      get () {
        const test = this.$store.getters.getReferral
        return test.total
      },
      set (value) {
        this.$store.dispatch('updateConversion', { name: 'referral', total: value })
      }
    },
    locationCounter: {
      get () {
        const test = this.$store.getters.getLocation
        return test.total
      },
      set (value) {
        this.$store.dispatch('updateConversion', { name: 'location', total: value })
      }
    }
  }
}
</script>
<style lang="stylus" scoped>

.v-list-item__action {
  flex-direction: row;
  span {
    margin-right: 5px;
  }
}

</style>
