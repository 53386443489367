<template lang="pug">

v-card(outlined)
  v-col
      v-list-item(three-line)
        v-list-item-content
          v-list-item-title.headline.mb-1 Percentage Price Calculator
          v-list-item-subtitle Enter the markup percentages and cost to calculate.
  v-divider
  v-form
    v-container
      v-row
        v-col
          v-text-field(
            v-model="msrp"
            type="number"
            label="MSRP %"
            outlined
            clearable
            prepend-inner-icon="monetization_on"
            hint="Enter MSRP percentage."
          )
        v-col
          v-text-field(
            v-model="price"
            type="number"
            label="Price %"
            outlined
            clearable
            prepend-inner-icon="monetization_on"
            hint="Enter Price percentage."
          )
      v-row
        v-col
          v-text-field(
            v-model="cost"
            type="number"
            label="Cost"
            outlined
            clearable
            prepend-inner-icon="monetization_on"
            hint="Enter Cost."
          )
        v-col
            p New MSRP: {{ roundedUpFiveMSRP }}
            p New Price: {{ roundedUpFivePrice}}
</template>
<script>
export default {
  data () {
    return {
      inchesInput: '',
      msrp: 30,
      price: 17,
      cost: ''
    }
  },
  methods: {},
  computed: {
    feetPlusInches () {
      if (this.inchesInput) {
        const totalInches = this.inchesInput
        const feet = Math.floor(totalInches / 12)
        const inches = totalInches % 12
        if (inches === 0) {
          return feet + "'"
        } else if (feet === 0) {
          return inches + '"'
        } else {
          return feet + "'" + inches + '"'
        }
      } else {
        return ''
      }
    },
    roundedUpFiveMSRP () {
      const markupValue = 100 - this.msrp
      const markupDecimal = markupValue / Math.pow(10, 2)
      const markupApplied = this.cost / markupDecimal
      return Math.ceil(markupApplied / 5) * 5
    },
    roundedUpFivePrice () {
      const markupValue = 100 - this.price
      const markupDecimal = markupValue / Math.pow(10, 2)
      const markupApplied = this.cost / markupDecimal
      return Math.ceil(markupApplied / 5) * 5
    }
  }
}
</script>
